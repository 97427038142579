import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'

import './TermsAndConditions.scss'
import SEO from '../../components/SEO/SEO'

const TermsAndConditions = () => {
  enum ContentType {
    TITLE = 'terms-and-conditions-title',
    BODY = 'body'
  }

  const termsData = useStaticQuery(graphql`
    query {
      termsAndConditions {
        containers {
          field_collections {
            fields {
              name
              value
            }
          }
        }
      }
    }
  `)

  const getContent = (name: any) => {
    const content = termsData.termsAndConditions?.containers[0]?.field_collections[0].fields.find((field: any) => field.name === name)
    return content ? content.value : ''
  }

  return (
    <div className="terms-and-conditions">
      <SEO title="Terms And Condition" description="" noIndex />
      <span onClick={() => navigate(-1)} className="alp-modal-close">
        &times;
      </span>
      {getContent(ContentType.TITLE) === '' && getContent(ContentType.BODY) === '' && (
        <h3 className="terms-empty-text">No data to display.</h3>
      )}

      <div className="content-wrapper">
        <h1 className="title">{getContent(ContentType.TITLE)}</h1>
        <p dangerouslySetInnerHTML={{ __html: getContent(ContentType.BODY) }}></p>
      </div>
    </div>
  )
}

export default TermsAndConditions
