import React from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'
import { navigate } from 'gatsby'
import './Privacy.scss'
import SEO from '../../components/SEO/SEO'

const PrivacyAndPolicy = (props: any) => {
  const {
    privacyAndPolicy: { containers }
  } = useStaticQuery(graphql`
    query {
      privacyAndPolicy {
        name
        containers {
          field_collections {
            fields {
              name
              value
            }
          }
        }
      }
    }
  `)

  const privacy = containers?.reduce((prev: any, container: any) => {
    const newObj: { [key: string]: string } = { ...prev }
    if (Array.isArray(container.field_collections)) {
      container.field_collections[0].fields?.forEach(({ value, name }: any) => {
        newObj[`${name}`] = value
      })
    }
    return newObj
  }, {})

  return (
    <>
      <div className="privacy-and-policy">
        <SEO title="Privacy Notice" description="" noIndex />
        <span onClick={() => navigate(-1)} className="alp-modal-close">
          &times;
        </span>
        {!privacy['privacy-policy-title'] && !privacy.body && <h3 className="privacy-empty-text">No data to display.</h3>}

        <h1 className="title">{privacy['privacy-policy-title']}</h1>

        <div className="content-wrapper" dangerouslySetInnerHTML={{ __html: privacy.body }} />
      </div>
    </>
  )
}

export default PrivacyAndPolicy
