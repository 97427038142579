import React, { SetStateAction } from 'react'
import loadable from '@loadable/component'
import { ArticleComponentType } from '../../../shared/types'
import { PropertyType, PropertyUnitsType, MetaDataType, ComponentType } from './PropertyPageContent.utils'
import CeilaBrochureImg from '../../../assets/images/ciela-at-aera-heights.webp'

import PropertyPageSlider from '../../../components/PropertyPageSlider/PropertyPageSlider'
import { Link } from 'gatsby'

const ConstructionModal = loadable(() => import('../../../components/ConstructionModal/ConstructionModal'))
const ContentComponents = loadable(() => import('../../../components/ContentComponents/ContentComponents'))
const ComponentThirteen = loadable(() => import('../../../components/ContentComponents/ComponentThirteen/ComponentThirteen'))

type PropertyPageContentProps = {
  propertyComponents: ArticleComponentType[] | null
  property: PropertyType
  unit: PropertyUnitsType
  getVirtualTourImg: () => any
  brochureComponent: ArticleComponentType | null
  constructionImage: MetaDataType
  toggleModal: React.Dispatch<SetStateAction<boolean>>
  showModal: boolean
  brochureTableComponent: ComponentType
}

export default function PropertyPageContent({
  propertyComponents,
  property,
  unit,
  getVirtualTourImg,
  brochureComponent,
  constructionImage,
  toggleModal,
  showModal,
  brochureTableComponent
}: PropertyPageContentProps): JSX.Element {
  const isCielaHeights = property.slug === 'ciela-at-aera-heights'
  const cielaBrochure = 'https://brochure.ayalalandpremier.com/view/645609973/'
  const unitDetails = unit?.overviewDetails?.overviewDetails

  return (
    <div className="property-page-content">
      <h1 style={{ display: 'none' }}>{property.propertyDetails.projectName}</h1>
      <ContentComponents components={propertyComponents} noMarginTop={true} />
      <div className="brochure-fold">
        {property?.propertyDetails?.propertyType !== 'Townhouse' ? (
          <div className="brochure-content">
            {unit?.floorPlans?.videos[0]?.video ? (
              <div className="brochure-top-content">
                {getVirtualTourImg().image && (
                  <a href={unit?.floorPlans?.videos[0]?.video} target="_blank" download className="no-br">
                    <img loading="lazy" src={getVirtualTourImg().image} alt={getVirtualTourImg().altText} />
                  </a>
                )}
                <a href={unit?.floorPlans?.videos[0]?.video} target="_blank" download>
                  View Virtual Tour
                </a>
              </div>
            ) : null}

            <div className="brochure-bottom-content">
              <div className="brochure-bottom-content-item">
                <a
                  href={isCielaHeights ? cielaBrochure : unitDetails?.file ? unitDetails?.file : brochureComponent?.description}
                  target="_blank"
                  download
                  className="no-br"
                  style={{ pointerEvents: isCielaHeights ? 'none' : 'auto' }}
                >
                  <img
                    loading="lazy"
                    src={isCielaHeights ? CeilaBrochureImg : brochureComponent?.image || brochureComponent?.image_1}
                    alt={brochureComponent?.image_alt_text || 'brochure download'}
                  />
                </a>
                <a
                  href={isCielaHeights ? cielaBrochure : unitDetails?.file ? unitDetails?.file : brochureComponent?.description}
                  target="_blank"
                  download
                >
                  View Interactive Brochure
                </a>
              </div>
              <div className="brochure-bottom-content-item">
                {constructionImage?.image && (
                  <div className="content-links">
                    <a onClick={() => toggleModal(!showModal)} className="no-br">
                      <img loading="lazy" src={constructionImage?.image} alt={constructionImage?.altText ?? 'Project Updates'} />
                    </a>
                    <a onClick={() => toggleModal(!showModal)}>View Project Updates</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <a className={brochureComponent?.description == '' ? 'back' : 'back no-desc'} href="/our-portfolio/established-communities/">
            Back to Communities
          </a>
        )}

        {property?.propertyDetails?.propertyType !== 'Townhouse'
          ? brochureTableComponent && <ComponentThirteen isContentOnly={true} component={brochureTableComponent} />
          : null}
      </div>

      {unit?.floorPlans?.unitsFloorPlans[0]?.imageGallery ? (
        <PropertyPageSlider {...{ floorPlans: property?.propertyUnits ? unit?.floorPlans : [] }} />
      ) : null}

      <ConstructionModal {...{ width: 'auto', height: 'auto%', showModal, toggleModal }}>
        <div className="construction-image">
          <img loading="lazy" src={constructionImage?.image} alt={constructionImage?.altText ?? 'Construction image'} />
        </div>
      </ConstructionModal>

      {property?.propertyDetails?.propertyType !== 'Townhouse' ? (
        <div className="bottom-links-container">
          {property?.propertyDetails?.propertyType !== 'Townhouse' ? (
            <div className="bottom-links">
              <div className="content-links">
                <Link to="/inquire/">
                  <p>Make an Inquiry</p>
                </Link>
              </div>
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  )
}
